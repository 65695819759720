/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import React from "react";
import { navigate } from "gatsby";

function encode(data) {
	return Object.keys(data)
		.map(
			key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
		)
		.join("&");
}

class ContactMeForm extends React.Component {
	constructor(props) {
		// Required step: always call the parent class' constructor
		super(props);

		this.ContactMeForm = React.createRef();

		// Set the state directly. Use props if necessary.
		this.state = {};
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = e => {
		e.preventDefault();
		const form = e.target;
		console.log(form);
		console.log(form.getAttribute("name"));

		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": form.getAttribute("name"),
				...this.state
			})
		})
			.then(() => navigate(form.getAttribute("action")))
			.catch(error => console.log(error));
	};

	render() {
		return (
			<form
				name={this.props.formName}
				method="POST"
				action="/contact/thanks"
				data-netlify="true"
				onSubmit={this.handleSubmit}
			>
				<input
					type="hidden"
					name="form-name"
					value={this.props.formName}
				/>
				<div className="field">
					<label className="label" htmlFor="firstName">
						Name
					</label>
					<div className="control">
						<input
							name="name"
							id="name"
							className="input"
							type="text"
							placeholder="John Lester"
							onChange={this.handleChange}
							required
						/>
					</div>
				</div>
				<div className="field">
					<label className="label" htmlFor="company">
						Company
					</label>
					<div className="control">
						<input
							name="form-company"
							id="company"
							className="input"
							type="text"
							placeholder="My Company, Ltd."
							onChange={this.handleChange}
							required
						/>
					</div>
				</div>
				<div className="field">
					<label className="label" htmlFor="website">
						Website
					</label>
					<div className="control">
						<input
							name="form-website"
							id="website"
							className="input"
							type="text"
							placeholder="www.example.com"
							onChange={this.handleChange}
							required
						/>
					</div>
				</div>
				<div className="field">
					<label className="label" htmlFor="email">
						Email
					</label>
					<div className="control">
						<input
							name="email"
							id="email"
							className="input"
							type="email"
							placeholder="you@example.com"
							onChange={this.handleChange}
							required
						/>
					</div>
				</div>
				<div className="field">
					<div className="control">
						<button className="button is-link">Submit</button>
					</div>
				</div>
			</form>
		);
	}
}

export default ContactMeForm;
